import React from 'react'
import Modal from '../modal'
import { FaCopy, FaTimes } from 'react-icons/fa'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'sonner'

function OrderDetails({orderDetail, setOrderDetail}) {
  return (
    <Modal modalContainerClassName='!h-fit !overflow-scroll'>
    <div className='flex items-center w-full justify-between mb-[30px] '>
      <p className='space-grotesk font-bold text-[1.1rem] text-[#393939]'>Order details</p>
      <div className='cursor-pointer' onClick={() => setOrderDetail(null)}>
        <FaTimes 
        color="black"
        />
      </div>
    </div>

    <div className='flex items-center justify-start w-full border-b-4 border-[#c0bebe] pb-4'>
    {orderDetail?.base_currency && (
        orderDetail.base_currency.toLowerCase() === 'eth'?
          <img 
          alt='btc'
          src='/assets/eth.png'
          className=' h-[50px] object-contain w-[50px] mr-2 '/>
        : orderDetail.base_currency.toLowerCase() === 'btc'?
        <img 
        alt='eth'
        src='/assets/btc.png'
        className=' h-[50px] object-contain w-[50px] mr-2 '/>:"")}

      <div className='flex flex-col w-[100%]'>
        <div className='flex items-center'>
        <p className='text-[1.2rem] text-[#393939] font-semibold capitalize'>{orderDetail?.miner_public_key}</p>

        <CopyToClipboard 
        onCopy={()=>toast.success('Copied')}
        text={orderDetail?.miner_public_key}>
        <FaCopy
        className='cursor-pointer ml-4'
        color="black"
        size={25}
        />
        </CopyToClipboard>
           
        </div>
        

        <p className='text-[#667085] text-[.8rem]'>{orderDetail?.base_currency} / {orderDetail?.count_currency}</p>
      </div>
    </div>

    <div className='w-full flex items-center justify-between mt-6'>
      <p className='text-[#6B7280] text-[.8rem] font-medium'>Order Type</p>
      <p className='text-[#111827] text-[.8rem] font-semibold capitalize'>{orderDetail?.order_type}</p>
    </div>
    <div className='w-full flex items-center justify-between mt-6'>
      <p className='text-[#6B7280] text-[.8rem] font-medium'>Postion Close</p>
      <p className='text-[#111827] text-[.8rem] font-semibold capitalize'>{orderDetail?.position_close}</p>
    </div>
    <div className='w-full flex items-center justify-between mt-6'>
      <p className='text-[#6B7280] text-[.8rem] font-medium'>Postion Open</p>
      <p className='text-[#111827] text-[.8rem] font-semibold capitalize'>{orderDetail?.position_open}</p>
    </div>
    <div className='w-full flex items-center justify-between mt-6'>
      <p className='text-[#6B7280] text-[.8rem] font-medium'>Leverage</p>
      <p className='text-[#111827] text-[.8rem] font-semibold'>{orderDetail?.leverage}</p>
    </div>
    <div className='w-full flex items-center justify-between mt-6'>
      <p className='text-[#6B7280] text-[.8rem] font-medium'>Net Leverage</p>
      <p className='text-[#111827] text-[.8rem] font-semibold'>{orderDetail?.net_leverage}</p>
    </div>
    <div className='w-full flex items-center justify-between mt-6'>
      <p className='text-[#6B7280] text-[.8rem] font-medium'>Initial Entry Price</p>
      <p className='text-[#111827] text-[.8rem] font-semibold capitalize'>${orderDetail?.initial_entry_price}</p>
    </div>
    <div className='w-full flex items-center justify-between mt-6'>
      <p className='text-[#6B7280] text-[.8rem] font-medium'>Price</p>
      <p className='text-[#111827] text-[.8rem] font-semibold'>${orderDetail?.price}</p>
    </div>
    <div className='w-full flex items-center justify-between mt-6'>
      <p className='text-[#6B7280] text-[.8rem] font-medium'>Order UUID</p>
      <p className='text-[#111827] text-[.8rem] font-semibold flex items-center'>{orderDetail?.order_uuid}  <CopyToClipboard 
        onCopy={()=>toast.success('Copied')}
        text={orderDetail?.order_uuid}>
        <FaCopy
        className='cursor-pointer ml-4'
        color="black"
        size={16}
        />
        </CopyToClipboard></p>
    </div>
    <div className='w-full flex items-center justify-between mt-6'>
      <p className='text-[#6B7280] text-[.8rem] font-medium'>Position UUID</p>
      <p className='text-[#111827] text-[.8rem] font-semibold flex items-center'>{orderDetail?.position_uuid}  <CopyToClipboard 
        onCopy={()=>toast.success('Copied')}
        text={orderDetail?.position_uuid}>
        <FaCopy
        className='cursor-pointer ml-4'
        color="black"
        size={16}
        />
        </CopyToClipboard></p>
    </div>
    <div className='w-full flex items-center justify-between mt-6'>
      <p className='text-[#6B7280] text-[.8rem] font-medium'>Return At Close</p>
      <p className='text-[#111827] text-[.8rem] font-semibold'>{orderDetail?.return_at_close}</p>
    </div>
  </Modal>
  )
}

export default OrderDetails