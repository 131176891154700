import { tokens } from "../theme";

export const mockDataTeam = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
];

export const mockDataInvoices = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    cost: "21.24",
    phone: "(665)121-5454",
    date: "03/12/2022",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    cost: "1.24",
    phone: "(421)314-2288",
    date: "06/15/2021",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    cost: "11.24",
    phone: "(422)982-6739",
    date: "05/02/2022",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    cost: "80.55",
    phone: "(921)425-6742",
    date: "03/21/2022",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    cost: "1.24",
    phone: "(421)445-1189",
    date: "01/12/2021",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    cost: "63.12",
    phone: "(232)545-6483",
    date: "11/02/2022",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    cost: "52.42",
    phone: "(543)124-0123",
    date: "02/11/2022",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    cost: "21.24",
    phone: "(222)444-5555",
    date: "05/02/2021",
  },
];

export const mockTransactions = [
  {
    txId: "01e4dsa",
    user: "johndoe",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "51034szv",
    user: "goodmanave",
    date: "2022-11-05",
    cost: "200.95",
  },
  {
    txId: "0a123sb",
    user: "stevebower",
    date: "2022-11-02",
    cost: "13.55",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "120s51a",
    user: "wootzifer",
    date: "2019-04-15",
    cost: "24.20",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
];

export const mockBarData = [
  {
    country: "AD",
    "hot dog": 137,
    "hot dogColor": "hsl(229, 70%, 50%)",
    burger: 96,
    burgerColor: "hsl(296, 70%, 50%)",
    kebab: 72,
    kebabColor: "hsl(97, 70%, 50%)",
    donut: 140,
    donutColor: "hsl(340, 70%, 50%)",
  },
  {
    country: "AE",
    "hot dog": 55,
    "hot dogColor": "hsl(307, 70%, 50%)",
    burger: 28,
    burgerColor: "hsl(111, 70%, 50%)",
    kebab: 58,
    kebabColor: "hsl(273, 70%, 50%)",
    donut: 29,
    donutColor: "hsl(275, 70%, 50%)",
  },
  {
    country: "AF",
    "hot dog": 109,
    "hot dogColor": "hsl(72, 70%, 50%)",
    burger: 23,
    burgerColor: "hsl(96, 70%, 50%)",
    kebab: 34,
    kebabColor: "hsl(106, 70%, 50%)",
    donut: 152,
    donutColor: "hsl(256, 70%, 50%)",
  },
  {
    country: "AG",
    "hot dog": 133,
    "hot dogColor": "hsl(257, 70%, 50%)",
    burger: 52,
    burgerColor: "hsl(326, 70%, 50%)",
    kebab: 43,
    kebabColor: "hsl(110, 70%, 50%)",
    donut: 83,
    donutColor: "hsl(9, 70%, 50%)",
  },
  {
    country: "AI",
    "hot dog": 81,
    "hot dogColor": "hsl(190, 70%, 50%)",
    burger: 80,
    burgerColor: "hsl(325, 70%, 50%)",
    kebab: 112,
    kebabColor: "hsl(54, 70%, 50%)",
    donut: 35,
    donutColor: "hsl(285, 70%, 50%)",
  },
  {
    country: "AL",
    "hot dog": 66,
    "hot dogColor": "hsl(208, 70%, 50%)",
    burger: 111,
    burgerColor: "hsl(334, 70%, 50%)",
    kebab: 167,
    kebabColor: "hsl(182, 70%, 50%)",
    donut: 18,
    donutColor: "hsl(76, 70%, 50%)",
  },
  {
    country: "AM",
    "hot dog": 80,
    "hot dogColor": "hsl(87, 70%, 50%)",
    burger: 47,
    burgerColor: "hsl(141, 70%, 50%)",
    kebab: 158,
    kebabColor: "hsl(224, 70%, 50%)",
    donut: 49,
    donutColor: "hsl(274, 70%, 50%)",
  },
];

export const mockBoxPlotData = [
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 3.5808893387089538,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.410577680394301,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 2.8802090615257843,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.672307649232653,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.35323671085657,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.692591654634814,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.199295103420349,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.533136416091573,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.204369798251115,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.709985664437746,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.009331875433842,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.505609937448417,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 6.4070375687149195,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.872400535703275,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 3.51005720564346,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 6.353360761386778,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.604756549616371,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 6.589924294908702,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.534274221988669,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.443059834144282,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 4.980250581973328,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.833957265587381,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.518617829703965,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.991817687584446,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.0624431220333435,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.67122709127688,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.894605892520604,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.164343998165444,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.959062996111589,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 4.025347343543135,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 4.184469954050693,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.226825665295396,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.344076160051411,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.197760801282708,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 3.4208530209897403,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.957682612995744,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.410230909824434,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.753080334406325,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.716815002391771,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.751327371181074,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 8.159812394492738,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 5.868389000117,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 8.63981303558227,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 8.070350225688601,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 7.850385305077269,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 7.938929708547017,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 8.842964033174589,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 9.485494560277878,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 8.745721562142325,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 8.893279864975142,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 8.812446917724166,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 8.802887067335341,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 6.951598418128269,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 8.107597787777483,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 8.730730616165182,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 9.803678640490626,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 7.5476040148754535,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 6.816514542154996,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 7.257797711003957,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 7.725926607905929,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.272092580147927,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.129672683298308,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 6.941670411643263,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.676654097742224,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 5.785315961783293,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.818670813889867,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 6.995911878151557,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 6.256286577927896,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 6.580785566670464,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 8.351619159942468,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.520954129758137,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 8.1590967105609,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.763863489802071,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.812384428250235,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.175275508611929,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 6.627596880358686,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 8.66231852060594,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 6.85481582794924,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.543278158571765,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 6.12099489958005,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.803217829924229,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.793270842091892,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.513096623197088,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.832148958815481,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.26461978653859,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.338785880091033,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 2.98356733741926,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.250886222558912,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 3.9375618678208633,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.510843349598981,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 2.7257088974405783,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 6.845713599995143,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.29987828130471,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.63273864746499,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.527343668743944,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 8.529742387060264,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.7568113096824405,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.824036293098538,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.168025161930224,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.845850043879032,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 10.161315010260287,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 9.128328561544519,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 8.381087512624788,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 8.847796955269798,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 7.968114089330518,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 6.564240874961617,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 6.395756756700129,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 5.346146429019161,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 8.00267581786415,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 9.449958387224886,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 7.591004719499146,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 7.414686004207698,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 7.95781258244651,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 8.448813042350366,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 8.801621288790898,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 7.157606144695922,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 5.164567197346197,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 3.3116790153771283,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 8.830349063957609,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 6.1213359626648245,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.0471493705763155,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.578648422639492,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.820249254735528,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 6.021137830554044,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.52135406542668,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.216275725449753,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.933645463020545,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.993451210492399,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.757804126276842,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 6.391264165467222,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.5980955732497435,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 6.172484858429849,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.1793980121032694,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.84114466420839,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.6166940370552485,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 3.9955826690447354,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.510762988022804,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.6069867654994825,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.571151206258871,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.143648832661506,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.826039189922622,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.439953903007384,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 4.971021751585713,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.846913096630284,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 4.967397372622093,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 4.974611816999545,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 4.478015763518833,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.379192770240275,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 7.293383514871617,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.849018381483632,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.577355452871452,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.629960920270808,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.436931884289857,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.515021645712603,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.5539389367896925,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 7.105021560860582,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.830900782426984,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.52664701325331,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.906751623189516,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.526879305585201,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 4.181797707286176,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 5.947426194646113,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 6.136789478570234,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 5.526023107197124,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 6.0388391963761485,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 8.501724416832484,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 3.95085725461674,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 4.6692200302107825,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 5.1635107677511405,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 4.19355930157927,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 1.8916074206484725,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 5.199772280412162,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 5.9355105504905765,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 5.205803461184921,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 4.364849486518654,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 5.710071359954293,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 5.203330311308591,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 4.137761575562976,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 5.051048875186294,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 5.024899806159128,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 4.950386795567425,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 3.355827386741926,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 5.268403317538493,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 6.232500344785842,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 6.735534915788978,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 3.9672261594079448,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: -3.4552405660989223,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 6.168295892172088,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 5.897492579762635,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 5.8036697019229475,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 6.371197583803779,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 5.532838579530241,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 5.414249245592076,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 4.709076466644788,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 1.5100904737116796,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 4.85938344402883,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 5.974309630421435,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 4.99967103956341,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 6.635621079583415,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    mu: 6,
    sd: 3,
    n: 20,
    value: 4.623768463452794,
  },
];

///
export const mockPieDataTarvisTimeless = [
  {
    id: "pie-chart-1",
    data: [
      {
        id: "5GhCxfBcA7Ur5iiAS343....",
        label: "5GhCxfBcA7Ur5iiAS343....",
        value: 239,
      },
    ],
  },
  {
    id: "pie-chart-2",
    data: [
      {
        id: "bitcoin",
        label: "BTC",
        value: 239,
      },
      {
        id: "ethereum",
        label: "ETH",
        value: 170,
      },
    ],
  },
];

export const mockPieDataOthers = [
  {
    id: "pie-chart-1",
    data: [
      {
        id: "5GhCxfBcA7Ur5iiAS3......",
        label: "5GhCxfBcA7Ur5iiAS3......",
        value: 239,
      },
    ],
  },
  {
    id: "pie-chart-2",
    data: [
      {
        id: "bitcoin",
        label: "BTC",
        value: 239,
      },
      {
        id: "ethereum",
        label: "ETH",
        value: 170,
      },
    ],
  },
];

export const mockLineDataTarvisTimeless = [
  {
    id: "# orders",
    color: tokens("dark").blueAccent[300],
    data: [
      {
        x: "Apr 2, 2024",
        y: 212,
      },
      {
        x: "Apr 3, 2024",
        y: 190,
      },
      {
        x: "Apr 4, 2024",
        y: 270,
      },
      {
        x: "Apr 5, 2024",
        y: 9,
      },
      {
        x: "Apr 7, 2024",
        y: 75,
      },
      {
        x: "Apr 12, 2024",
        y: 175,
      },
      {
        x: "Apr 13, 2024",
        y: 33,
      },
      {
        x: "Apr 14, 2024",
        y: 189,
      },
      {
        x: "Apr 15, 2024",
        y: 97,
      },
      {
        x: "Apr 16, 2024",
        y: 87,
      },
      {
        x: "Apr 17, 2024",
        y: 299,
      },
      {
        x: "Apr 18, 2024",
        y: 251,
      },
      {
        x: "Apr 19, 2024",
        y: 100,
      },
    ],
  },
];

export const mockLineDataOthers = [
  {
    id: "# orders",
    color: tokens("dark").blueAccent[300],
    data: [
      {
        x: "Apr 2, 2024",
        y: 212,
      },
      {
        x: "Apr 3, 2024",
        y: 190,
      },
      {
        x: "Apr 4, 2024",
        y: 270,
      },
      {
        x: "Apr 5, 2024",
        y: 9,
      },
      {
        x: "Apr 7, 2024",
        y: 75,
      },
      {
        x: "Apr 12, 2024",
        y: 175,
      },
      {
        x: "Apr 13, 2024",
        y: 33,
      },
      {
        x: "Apr 14, 2024",
        y: 189,
      },
      {
        x: "Apr 15, 2024",
        y: 97,
      },
      {
        x: "Apr 16, 2024",
        y: 87,
      },
      {
        x: "Apr 17, 2024",
        y: 299,
      },
      {
        x: "Apr 18, 2024",
        y: 251,
      },
      {
        x: "Apr 19, 2024",
        y: 100,
      },
    ],
  },
];

export const mockStatDataTarvisTimeless = [
  {
    title: "10",
    subtitle: "# of positions",
    id: "positions",
  },
  {
    title: "0",
    subtitle: "# of open positions",
    id: "open-positions",
  },
  {
    title: "55",
    subtitle: "# of orders",
    id: "orders",
  },
  {
    title: "1.004703",
    subtitle: "avg return",
    id: "avg-return",
  },
];

export const mockStatDataOthers = [
  {
    title: "1,578",
    subtitle: "# of positions",
    id: "positions",
  },
  {
    title: "1",
    subtitle: "# of open positions",
    id: "open-positions",
  },
  {
    title: "5,335",
    subtitle: "# of orders",
    id: "orders",
  },
  {
    title: "0.996787",
    subtitle: "avg return",
    id: "avg-return",
  },
];

export const mockTableDataTarvisTimeless = [
  {
    id: 1,
    position_uuid: "37e59df9-26a4-43eb-b1f6-d37828ac6132",
    order_uuid: "ad8b6b05-2d73-4e2f-9e62-c2ec1a60650a",
    order_type: "LONG",
    base_currency: "BTC",
    count_currency: "USD",
    miner_public_key: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
    position_open: "Apr 2, 2024, 12:01:12 PM",
    position_close: "Apr 4, 2024, 12:01:10 PM",
    price: 64930.26,
    leverage: 0.033,
    net_leverage: 0,
    initial_entry: 65520.31,
    is_closed_position: true,
    current_return: 1.0009608635856577,
    return_at_close: 1.000894800168661,
  },
  {
    id: 2,
    position_uuid: "37e59df9-26a4-43eb-b1f6-d37828ac6132",
    order_uuid: "ad8b6b05-2d73-4e2f-9e62-c2ec1a60650a",
    order_type: "LONG",
    base_currency: "BTC",
    count_currency: "USD",
    miner_public_key: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
    position_open: "Apr 2, 2024, 12:01:12 PM",
    position_close: "Apr 4, 2024, 12:01:10 PM",
    price: 64930.26,
    leverage: 0.033,
    net_leverage: 0,
    initial_entry: 65520.31,
    is_closed_position: true,
    current_return: 1.0009608635856577,
    return_at_close: 1.000894800168661,
  },
  {
    id: 3,
    position_uuid: "37e59df9-26a4-43eb-b1f6-d37828ac6132",
    order_uuid: "ad8b6b05-2d73-4e2f-9e62-c2ec1a60650a",
    order_type: "LONG",
    base_currency: "BTC",
    count_currency: "USD",
    miner_public_key: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
    position_open: "Apr 2, 2024, 12:01:12 PM",
    position_close: "Apr 4, 2024, 12:01:10 PM",
    price: 64930.26,
    leverage: 0.033,
    net_leverage: 0,
    initial_entry: 65520.31,
    is_closed_position: true,
    current_return: 1.0009608635856577,
    return_at_close: 1.000894800168661,
  },
];

export const mockTableDataOthers = [
  {
    id: 1,
    position_uuid: "37e59df9-26a4-43eb-b1f6-d37828ac6132",
    order_uuid: "ad8b6b05-2d73-4e2f-9e62-c2ec1a60650a",
    order_type: "LONG",
    base_currency: "BTC",
    count_currency: "USD",
    miner_public_key: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
    position_open: "Apr 2, 2024, 12:01:12 PM",
    position_close: "Apr 4, 2024, 12:01:10 PM",
    price: 64930.26,
    leverage: 0.033,
    net_leverage: 0,
    initial_entry: 65520.31,
    is_closed_position: true,
    current_return: 1.0009608635856577,
    return_at_close: 1.000894800168661,
  },
  {
    id: 2,
    position_uuid: "37e59df9-26a4-43eb-b1f6-d37828ac6132",
    order_uuid: "ad8b6b05-2d73-4e2f-9e62-c2ec1a60650a",
    order_type: "LONG",
    base_currency: "BTC",
    count_currency: "USD",
    miner_public_key: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
    position_open: "Apr 2, 2024, 12:01:12 PM",
    position_close: "Apr 4, 2024, 12:01:10 PM",
    price: 64930.26,
    leverage: 0.033,
    net_leverage: 0,
    initial_entry: 65520.31,
    is_closed_position: true,
    current_return: 1.0009608635856577,
    return_at_close: 1.000894800168661,
  },
  {
    id: 3,
    position_uuid: "37e59df9-26a4-43eb-b1f6-d37828ac6132",
    order_uuid: "ad8b6b05-2d73-4e2f-9e62-c2ec1a60650a",
    order_type: "LONG",
    base_currency: "BTC",
    count_currency: "USD",
    miner_public_key: "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t",
    position_open: "Apr 2, 2024, 12:01:12 PM",
    position_close: "Apr 4, 2024, 12:01:10 PM",
    price: 64930.26,
    leverage: 0.033,
    net_leverage: 0,
    initial_entry: 65520.31,
    is_closed_position: true,
    current_return: 1.0009608635856577,
    return_at_close: 1.000894800168661,
  },
];
///

export const mockGeographyData = [
  {
    id: "AFG",
    value: 520600,
  },
  {
    id: "AGO",
    value: 949905,
  },
  {
    id: "ALB",
    value: 329910,
  },
  {
    id: "ARE",
    value: 675484,
  },
  {
    id: "ARG",
    value: 432239,
  },
  {
    id: "ARM",
    value: 288305,
  },
  {
    id: "ATA",
    value: 415648,
  },
  {
    id: "ATF",
    value: 665159,
  },
  {
    id: "AUT",
    value: 798526,
  },
  {
    id: "AZE",
    value: 481678,
  },
  {
    id: "BDI",
    value: 496457,
  },
  {
    id: "BEL",
    value: 252276,
  },
  {
    id: "BEN",
    value: 440315,
  },
  {
    id: "BFA",
    value: 343752,
  },
  {
    id: "BGD",
    value: 920203,
  },
  {
    id: "BGR",
    value: 261196,
  },
  {
    id: "BHS",
    value: 421551,
  },
  {
    id: "BIH",
    value: 974745,
  },
  {
    id: "BLR",
    value: 349288,
  },
  {
    id: "BLZ",
    value: 305983,
  },
  {
    id: "BOL",
    value: 430840,
  },
  {
    id: "BRN",
    value: 345666,
  },
  {
    id: "BTN",
    value: 649678,
  },
  {
    id: "BWA",
    value: 319392,
  },
  {
    id: "CAF",
    value: 722549,
  },
  {
    id: "CAN",
    value: 332843,
  },
  {
    id: "CHE",
    value: 122159,
  },
  {
    id: "CHL",
    value: 811736,
  },
  {
    id: "CHN",
    value: 593604,
  },
  {
    id: "CIV",
    value: 143219,
  },
  {
    id: "CMR",
    value: 630627,
  },
  {
    id: "COG",
    value: 498556,
  },
  {
    id: "COL",
    value: 660527,
  },
  {
    id: "CRI",
    value: 60262,
  },
  {
    id: "CUB",
    value: 177870,
  },
  {
    id: "-99",
    value: 463208,
  },
  {
    id: "CYP",
    value: 945909,
  },
  {
    id: "CZE",
    value: 500109,
  },
  {
    id: "DEU",
    value: 63345,
  },
  {
    id: "DJI",
    value: 634523,
  },
  {
    id: "DNK",
    value: 731068,
  },
  {
    id: "DOM",
    value: 262538,
  },
  {
    id: "DZA",
    value: 760695,
  },
  {
    id: "ECU",
    value: 301263,
  },
  {
    id: "EGY",
    value: 148475,
  },
  {
    id: "ERI",
    value: 939504,
  },
  {
    id: "ESP",
    value: 706050,
  },
  {
    id: "EST",
    value: 977015,
  },
  {
    id: "ETH",
    value: 461734,
  },
  {
    id: "FIN",
    value: 22800,
  },
  {
    id: "FJI",
    value: 18985,
  },
  {
    id: "FLK",
    value: 64986,
  },
  {
    id: "FRA",
    value: 447457,
  },
  {
    id: "GAB",
    value: 669675,
  },
  {
    id: "GBR",
    value: 757120,
  },
  {
    id: "GEO",
    value: 158702,
  },
  {
    id: "GHA",
    value: 893180,
  },
  {
    id: "GIN",
    value: 877288,
  },
  {
    id: "GMB",
    value: 724530,
  },
  {
    id: "GNB",
    value: 387753,
  },
  {
    id: "GNQ",
    value: 706118,
  },
  {
    id: "GRC",
    value: 377796,
  },
  {
    id: "GTM",
    value: 66890,
  },
  {
    id: "GUY",
    value: 719300,
  },
  {
    id: "HND",
    value: 739590,
  },
  {
    id: "HRV",
    value: 929467,
  },
  {
    id: "HTI",
    value: 538961,
  },
  {
    id: "HUN",
    value: 146095,
  },
  {
    id: "IDN",
    value: 490681,
  },
  {
    id: "IND",
    value: 549818,
  },
  {
    id: "IRL",
    value: 630163,
  },
  {
    id: "IRN",
    value: 596921,
  },
  {
    id: "IRQ",
    value: 767023,
  },
  {
    id: "ISL",
    value: 478682,
  },
  {
    id: "ISR",
    value: 963688,
  },
  {
    id: "ITA",
    value: 393089,
  },
  {
    id: "JAM",
    value: 83173,
  },
  {
    id: "JOR",
    value: 52005,
  },
  {
    id: "JPN",
    value: 199174,
  },
  {
    id: "KAZ",
    value: 181424,
  },
  {
    id: "KEN",
    value: 60946,
  },
  {
    id: "KGZ",
    value: 432478,
  },
  {
    id: "KHM",
    value: 254461,
  },
  {
    id: "OSA",
    value: 942447,
  },
  {
    id: "KWT",
    value: 414413,
  },
  {
    id: "LAO",
    value: 448339,
  },
  {
    id: "LBN",
    value: 620090,
  },
  {
    id: "LBR",
    value: 435950,
  },
  {
    id: "LBY",
    value: 75091,
  },
  {
    id: "LKA",
    value: 595124,
  },
  {
    id: "LSO",
    value: 483524,
  },
  {
    id: "LTU",
    value: 867357,
  },
  {
    id: "LUX",
    value: 689172,
  },
  {
    id: "LVA",
    value: 742980,
  },
  {
    id: "MAR",
    value: 236538,
  },
  {
    id: "MDA",
    value: 926836,
  },
  {
    id: "MDG",
    value: 840840,
  },
  {
    id: "MEX",
    value: 353910,
  },
  {
    id: "MKD",
    value: 505842,
  },
  {
    id: "MLI",
    value: 286082,
  },
  {
    id: "MMR",
    value: 915544,
  },
  {
    id: "MNE",
    value: 609500,
  },
  {
    id: "MNG",
    value: 410428,
  },
  {
    id: "MOZ",
    value: 32868,
  },
  {
    id: "MRT",
    value: 375671,
  },
  {
    id: "MWI",
    value: 591935,
  },
  {
    id: "MYS",
    value: 991644,
  },
  {
    id: "NAM",
    value: 701897,
  },
  {
    id: "NCL",
    value: 144098,
  },
  {
    id: "NER",
    value: 312944,
  },
  {
    id: "NGA",
    value: 862877,
  },
  {
    id: "NIC",
    value: 90831,
  },
  {
    id: "NLD",
    value: 281879,
  },
  {
    id: "NOR",
    value: 224537,
  },
  {
    id: "NPL",
    value: 322331,
  },
  {
    id: "NZL",
    value: 86615,
  },
  {
    id: "OMN",
    value: 707881,
  },
  {
    id: "PAK",
    value: 158577,
  },
  {
    id: "PAN",
    value: 738579,
  },
  {
    id: "PER",
    value: 248751,
  },
  {
    id: "PHL",
    value: 557292,
  },
  {
    id: "PNG",
    value: 516874,
  },
  {
    id: "POL",
    value: 682137,
  },
  {
    id: "PRI",
    value: 957399,
  },
  {
    id: "PRT",
    value: 846430,
  },
  {
    id: "PRY",
    value: 720555,
  },
  {
    id: "QAT",
    value: 478726,
  },
  {
    id: "ROU",
    value: 259318,
  },
  {
    id: "RUS",
    value: 268735,
  },
  {
    id: "RWA",
    value: 136781,
  },
  {
    id: "ESH",
    value: 151957,
  },
  {
    id: "SAU",
    value: 111821,
  },
  {
    id: "SDN",
    value: 927112,
  },
  {
    id: "SDS",
    value: 966473,
  },
  {
    id: "SEN",
    value: 158085,
  },
  {
    id: "SLB",
    value: 178389,
  },
  {
    id: "SLE",
    value: 528433,
  },
  {
    id: "SLV",
    value: 353467,
  },
  {
    id: "ABV",
    value: 251,
  },
  {
    id: "SOM",
    value: 445243,
  },
  {
    id: "SRB",
    value: 202402,
  },
  {
    id: "SUR",
    value: 972121,
  },
  {
    id: "SVK",
    value: 319923,
  },
  {
    id: "SVN",
    value: 728766,
  },
  {
    id: "SWZ",
    value: 379669,
  },
  {
    id: "SYR",
    value: 16221,
  },
  {
    id: "TCD",
    value: 101273,
  },
  {
    id: "TGO",
    value: 498411,
  },
  {
    id: "THA",
    value: 506906,
  },
  {
    id: "TJK",
    value: 613093,
  },
  {
    id: "TKM",
    value: 327016,
  },
  {
    id: "TLS",
    value: 607972,
  },
  {
    id: "TTO",
    value: 936365,
  },
  {
    id: "TUN",
    value: 898416,
  },
  {
    id: "TUR",
    value: 237783,
  },
  {
    id: "TWN",
    value: 878213,
  },
  {
    id: "TZA",
    value: 442174,
  },
  {
    id: "UGA",
    value: 720710,
  },
  {
    id: "UKR",
    value: 74172,
  },
  {
    id: "URY",
    value: 753177,
  },
  {
    id: "USA",
    value: 658725,
  },
  {
    id: "UZB",
    value: 550313,
  },
  {
    id: "VEN",
    value: 707492,
  },
  {
    id: "VNM",
    value: 538907,
  },
  {
    id: "VUT",
    value: 650646,
  },
  {
    id: "PSE",
    value: 476078,
  },
  {
    id: "YEM",
    value: 957751,
  },
  {
    id: "ZAF",
    value: 836949,
  },
  {
    id: "ZMB",
    value: 714503,
  },
  {
    id: "ZWE",
    value: 405217,
  },
  {
    id: "KOR",
    value: 171135,
  },
];
