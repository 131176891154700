import React from 'react'
import Shimmer from '../minerPerformance/Shimmer'

function SkeletonTable() {
  return (
    <div className='flex justify-center items-center flex-wrap w-full gap-2 overflow-x-hidden'>
    {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40, 41,42,43,44,45,46,47,48,49,50].map((n)=>(
         <div className='bg-[#ddd] h-[10px] w-[15%] rounded-lg m-4'>
      
         </div>
    ))}
    <Shimmer/>
    </div>
  )
}

export default SkeletonTable