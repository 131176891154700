import { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Orders from "./scenes/orders";
import {
  mockLineDataOthers,
  mockLineDataTarvisTimeless,
  mockPieDataOthers,
  mockPieDataTarvisTimeless,
  mockStatDataOthers,
  mockStatDataTarvisTimeless,
  mockTableDataOthers,
  mockTableDataTarvisTimeless,
} from "./data/mockData";
import MinerPerformance from "./scenes/minerPerformance";
import DailyReturnsContext from "./context/DailyReturnsContext";

function DashboardApp() {
  const [theme, colorMode] = useMode();
  const {showSidebar,setShowSidebar} = useContext(DailyReturnsContext)


  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app flex items-center w-full">
         
         {showSidebar && <Sidebar showSidebar={setShowSidebar} />} 
          <main className="md:ml-[250px] content min-h-fit h-fit bg-[#f6f5fa]">
            {/* <Topbar setIsSidebar={setIsSidebar} /> */}

            <Routes>
              <Route
                path="/"
                element={
                  <Dashboard
                    type="Tarvis"
                    statData={mockStatDataTarvisTimeless}
                    pieChartData={mockPieDataTarvisTimeless}
                    lineChartData={mockLineDataTarvisTimeless}
                  />
                }
              />
              <Route
                path="/others"
                element={
                  <Dashboard
                    type="Others"
                    statData={mockStatDataOthers}
                    pieChartData={mockPieDataOthers}
                    lineChartData={mockLineDataOthers}
                  />
                }
              />
              {/* <Route
                path="/charts/tarvis"
                element={<Charts type="Tarvis" data={mockBoxPlotData} />}
              />
              <Route
                path="/charts/timeless"
                element={<Charts type="Timeless" data={mockBoxPlotData} />}
              /> */}
              <Route
                path="/orders"
                element={
                  <Orders
                    type="Tarvis"
                    tableData={mockTableDataTarvisTimeless}
                  />
                }
              />
              <Route
                path="/orders/others"
                element={
                  <Orders type="Others" tableData={mockTableDataOthers} />
                }
              />
              <Route path="/minerperformance" element={<MinerPerformance />} />
              {/* <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/contacts" element={<Contacts />} /> */}
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default DashboardApp;
