import React, { useContext, useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { fetchDashboardData } from "../../functions";
import moment from 'moment';
import TableComponent from './TableComponent';
import DailyReturnsContext from '../../context/DailyReturnsContext';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'sonner';
import { FaCopy, FaRegTimesCircle } from 'react-icons/fa';
import SkeletonTable from '../skeletons/SkeletonTable';
import { mockDataPositions } from '../../data/mockPositionsData';




function OrdersComponent({ type }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const {positionsForOrders} = useContext(DailyReturnsContext)
  const [searchTerm,setSearchTerm] = useState('')
  const [page, setPage] = React.useState(1);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState('ascending');

  const sortData = (data, key, direction = 'descending') => {
    return [...data].sort((a, b) => {
      const first = a[key];
      const second = b[key];
      let cmp = 0;

      if (typeof first === 'number' && typeof second === 'number') {
        cmp = first - second;
      } else if (typeof first === 'string' && typeof second === 'string') {
        cmp = first.localeCompare(second);
      } else if (!isNaN(Date.parse(first)) && !isNaN(Date.parse(second))) {
        cmp = new Date(first) - new Date(second);
      }
      return direction === 'descending' ? -cmp : cmp;
    });
  };

  const handleSortChange = (e) => {
    const key = e.target.value;
    setSortKey(key);
    const sortedData = sortData(tableData, key, sortDirection);
    setTableData(sortedData);
  };

  const handleDirectionChange = (e) => {
    const direction = e.target.value;
    setSortDirection(direction);
    const sortedData = sortData(tableData, sortKey, direction);
    setTableData(sortedData);
  };

  const tarvisAndTimelessAddress =
    "5GhCxfBcA7Ur5iiAS343xwvrYHTUfBjBi4JimiL5LhujRT9t";

  const tarvisTwo = '5EZSgUEHCZX4jYxFXmDMJ2vraS12t5YQCcFYS87h2PgbQnyD'

  const columns = [
    {
      key: 'id',
      label: 'S. No'
    },

    {
      key: 'base_currency',
      label: 'Base Currency'
    },
    {
      key: 'order_type',
      label: 'Order Type'
    },
    {
      key: "miner_public_key",
      label: "Miner Public Key",
    },
    {
      key: "position_open",
      label: "Position Open",
    },
    {
      key: "position_close",
      label: "Position Close",
    },
    {
      key: "price",
      label: "Price",

    },
    {
      key: "current_return",
      label: "Return",

    },
    {
      key:"view_more",
      label:''
    }
  ];


  const getOrdersData = async () => {
    if(positionsForOrders){
      setLoading(true);
      if (type === "Tarvis") {
        const tarvisPositionsData = positionsForOrders[tarvisAndTimelessAddress].positions.filter(
          (item) =>
            item.trade_pair.includes("ETHUSD") ||
            item.trade_pair.includes("BTCUSD")
        );
  
        let idVal = 0;
        const tarvisTableOrders2d = tarvisPositionsData.map(
          ({
            average_entry_price,
            close_ms,
            miner_hotkey,
            open_ms,
            orders,
            position_type,
            trade_pair,
            ...resetItemData
          }) =>
            orders.map(({ price_sources, processed_ms, ...restOrderData }) => {
              return {
                ...resetItemData,
                ...restOrderData,
                id: ++idVal,
                base_currency: trade_pair[1].split("/")[0],
                count_currency: trade_pair[1].split("/")[1],
                miner_public_key: miner_hotkey,
                position_open: open_ms,
                position_close:close_ms,
              };
            })
        );
  
        const tarvisTableOrders = [].concat(...tarvisTableOrders2d);
  
        setTableData(tarvisTableOrders);
      }
      else if (type === 'TarvisTwo'){
        const tarvisPositionsData = positionsForOrders[
          tarvisTwo
        ].positions.filter(
          (item) =>
            item.trade_pair.includes("ETHUSD") ||
            item.trade_pair.includes("BTCUSD")
        );
  
        let idVal = 0;
        const tarvisTableOrders2d = tarvisPositionsData.map(
          ({
            average_entry_price,
            close_ms,
            miner_hotkey,
            open_ms,
            orders,
            position_type,
            trade_pair,
            ...resetItemData
          }) =>
            orders.map(({ price_sources, processed_ms, ...restOrderData }) => {
              return {
                ...resetItemData,
                ...restOrderData,
                id: ++idVal,
                base_currency: trade_pair[1].split("/")[0],
                count_currency: trade_pair[1].split("/")[1],
                miner_public_key: miner_hotkey,
                position_open: open_ms,
                position_close:close_ms,
              };
            })
        );
  
        const tarvisTableOrders = [].concat(...tarvisTableOrders2d);
  
        setTableData(tarvisTableOrders);
      }
      else {
        const clonedData = JSON.parse(JSON.stringify(positionsForOrders));
        delete clonedData[tarvisAndTimelessAddress];
        delete clonedData[tarvisTwo];


        const othersData = Object.keys(clonedData).map(
          (item) => clonedData[item]
        );
        const othersPositionsData2d = othersData.map((item) =>
          item.positions.filter(
            (item) =>
              item.trade_pair.includes("ETHUSD") ||
              item.trade_pair.includes("BTCUSD")
          )
        );
        const othersPositionsData = [].concat(...othersPositionsData2d);
  
        let idVal = 0;
        const othersTableOrders2d = othersPositionsData.map(
          ({
            average_entry_price,
            close_ms,
            miner_hotkey,
            open_ms,
            orders,
            position_type,
            trade_pair,
            ...resetItemData
          }) =>
            orders.map(({ price_sources, processed_ms, ...restOrderData }) => {
              return {
                ...resetItemData,
                ...restOrderData,
                id: ++idVal,
                base_currency: trade_pair[1].split("/")[0],
                count_currency: trade_pair[1].split("/")[1],
                miner_public_key: miner_hotkey,
                position_open:open_ms,
                position_close:close_ms
              };
            })
        );
        const othersTableOrders = [].concat(...othersTableOrders2d);
  
        setTableData(othersTableOrders);
      }
  
      setLoading(false);

    }
  };

  useEffect(() => {
    getOrdersData();
  }, [type]);

  const handleSearch=()=>{
    setPage(1)
    const results = tableData.filter((data)=>{
      return data.miner_public_key === searchTerm
    })
    setTableData(results)
  }


  return (
    <div className='p-4 h-fit'>
    {type ==='Tarvis' && <div className='flex mb-4 mt-[-20px] items-center'><p className='text-[1.3rem] text-[#393939] font-semibold '>{tarvisAndTimelessAddress}</p>
    <span> <CopyToClipboard
        onCopy={()=>toast.success('Copied')}
        text={tarvisAndTimelessAddress}>
        <FaCopy
        className='cursor-pointer ml-4'
        color="black"
        size={25}
        />
        </CopyToClipboard></span>
      </div>}

      {type ==='TarvisTwo' && <div className='flex mb-4 mt-[-20px] items-center'><p className='text-[1.3rem] text-[#393939] font-semibold '>{tarvisTwo}</p>
      <span> <CopyToClipboard
        onCopy={()=>toast.success('Copied')}
        text={tarvisTwo}>
        <FaCopy
        className='cursor-pointer ml-4'
        color="black"
        size={25}
        />
        </CopyToClipboard></span>
      </div>
      }
      
      <div className='w-full flex justify-start my-2 items-center'>
        <p className='text-[#393939]'>Sort By:</p>
        <select
          value={sortKey}
          onChange={handleSortChange}
          className='capitalize border p-2 rounded-lg text-[#393939]'>
          {["Select Option","position_open", "position_close","price", "leverage", "net_leverage", "current_return"].map((item) => (
            <option key={item} value={item}>
              {item.replace(/_/g, " ")}
            </option>
          ))}
        </select>
        <select
          value={sortDirection}
          onChange={handleDirectionChange}
          className='capitalize border p-2 rounded-lg text-[#393939] ml-2'>
          {['Select Option','ascending', 'descending'].map((direction) => (
            <option key={direction} value={direction}>
              {direction}
            </option>
          ))}
        </select>
      </div>
      {!(type === 'Tarvis' || type === 'TarvisTwo') &&  <div className="flex h-fit w-full items-center gap-2">
              <div className="my-3 flex w-full items-center rounded-xl border bg-white p-2 focus:border-2 focus:border-[#393939]">
                <input
                placeholder="Search By Address..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className=" h-[30px] w-full  text-[#393939] outline-none outline-0  transition-all delay-[.1s]"
                />
                <FaRegTimesCircle 
                onClick={()=>{
                  getOrdersData()
                  setSearchTerm('')
                }}
                color="#393939" className="cursor-pointer" />
              </div>

              <button
                disabled={searchTerm.length < 1}
                onClick={handleSearch}
                className="rounded-md bg-[#393939] p-2 text-white disabled:opacity-[.3] disabled:cursor-not-allowed"
              >
                Search
              </button>
            </div>}
            {(!positionsForOrders || !tableData) && <SkeletonTable/>}
    <TableComponent
     users={tableData}
     columns={columns}
         page={page}
         setPage={setPage}
            />  
    </div>
  );
}

export default OrdersComponent