'use client'
import React from 'react'


function Modal({children,containerClassName,modalContainerClassName}) {
  return (
    <div className='bg-[rgba(0,0,0,0.3)] z-[100000] fixed w-full h-full top-0 left-0 flex justify-center items-center'>
        <div className={`modal-class lg:w-[700px] w-[80vw] h-fit p-5 rounded-xl bg-[#ffff] flex flex-col justify-center items-center' ${modalContainerClassName}`}>
        <div className={`w-full containerClassName',${containerClassName}`}>
        {children}
        </div>
    </div>
    </div>
  )
}

export default Modal;