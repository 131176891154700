import React, { useContext, useState, useMemo, useCallback } from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Pagination } from "@nextui-org/react";
import DailyReturnsContext from "../../context/DailyReturnsContext";
import SkeletonTable from "../skeletons/SkeletonTable";
import { v4 as uuidv4 } from 'uuid';
import { truncateToFourDecimalPlaces } from "../../functions";




const statusColorMap = {
  Transportation: "secondary",
  Accomodation: "danger",
  Uncategorized: "default",
  Legal: "success",
  active: "success",
  Suspended: "danger",
  Paid: 'success',
  Pending: 'warning'
};

export default function PerformanceTable({ users, columns, selectionMode, setPerformanceData, performanceData }) {
  const [orderDetail, setOrderDetail] = useState(null);
  const { addressSelected, setAddressSelected } = useContext(DailyReturnsContext);
  const [page, setPage] = useState(1);
  const [expandedRows, setExpandedRows] = useState({});
  console.log(users);

  const rowsPerPage = 20;

  const groupedUsers = useMemo(() => {
    return users.reduce((acc, user) => {
      if (!acc[user.position_id]) {
        acc[user.position_id] = [];
      }
      acc[user.position_id].push(user);
      return acc;
    }, {});
  }, [users]);

  const calculateTotalRows = useCallback(() => {
    let count = 0;
    for (const position_id in groupedUsers) {
      count += 1; // Always count the group header
      if (expandedRows[position_id]) {
        count += groupedUsers[position_id].length; // Count the rest of the group if expanded
      }
    }
    return count;
  }, [groupedUsers, expandedRows]);

  const totalRows = calculateTotalRows();
  const pages = Math.ceil(totalRows / rowsPerPage);

  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    const flatGroupedUsers = Object.keys(groupedUsers).flatMap(position_id => {
      const rows = groupedUsers[position_id];
      const isExpanded = expandedRows[position_id];
      const headerRow = rows[rows.length - 1];
      return [
        { ...headerRow, isGroupHeader: true, rowCount: rows.length },
        ...(isExpanded ? rows : [])
      ];
    });

    return flatGroupedUsers.slice(start, end);
  }, [page, groupedUsers, expandedRows, rowsPerPage]);

  const toggleExpand = (position_id) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [position_id]: !prevState[position_id],
    }));
  };

  const getKeyValue = useCallback((user, columnKey) => {
    const cellValue = user[columnKey];

    switch (columnKey) {
      case "base_currency":
        return (
          <div className="flex justify-center 2xl:justify-start">
            {cellValue.toLowerCase() === 'btc' ? <img
              alt='btc'
              className="w-[30px] h-[30px]"
              src='/assets/btc.png' /> : cellValue.toLowerCase() === 'eth' ? <img
              alt='eth'
              className="w-[30px] h-[30px]"
              src='/assets/eth.png' /> : 'Not Supported'}
          </div>
        );
      case "asset":
        return (
          <div className="flex justify-center">
            {cellValue.toLowerCase() === 'btc' ? <img
              alt='btc'
              className="w-[30px] h-[30px] object-contain"
              src='/assets/btc.png' /> : cellValue.toLowerCase() === 'eth' ? <img
              alt='eth'
              className="w-[30px] h-[30px] object-contain"
              src='/assets/eth.png' /> : 'Not Supported'}
          </div>
        );
      case "order_type":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue}
          </div>
        );
      case "market_value_eth"://for tokens held
        return (
          <div className='text-[#393939]'>
           $ {Number(cellValue).toFixed(2)}
          </div>
        );
      case "market_value_btc"://for tokens held
        return (
          <div className='text-[#393939]'>
           $ {Number(cellValue).toFixed(2)}
          </div>
        );
      case "total_position_size_eth":
        return (
          <div className='text-[#393939] font-semibold'>
           {truncateToFourDecimalPlaces(Number(cellValue))}
          </div>
        );
      case "total_position_size_btc":
        return (
          <div className='text-[#393939] font-semibold'>
             {truncateToFourDecimalPlaces(Number(cellValue))}
          </div>
        );
      case "price":
        return (
          <div className='text-[#393939] font-semibold'>
            ${cellValue}
          </div>
        );
      case "miner_public_key":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue.substring(0, 10)}....
          </div>
        );
      case "avg_return_thirty_days":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue.toFixed(4)}
          </div>
        );
      case "avg_return":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue.toFixed(4)}
          </div>
        );
      case "portfolio_value":
        return (
          <div className='text-[#393939] font-semibold'>
            ${cellValue}
          </div>
        );
      case "processed_time":
        return (
          <div className=''>
            {new Date(cellValue).toLocaleString()}
          </div>
        );
      case "sharpe_ratio":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue.toFixed(4)}
          </div>
        );
      case "sortino_ratio":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue.toFixed(4)}
          </div>
        );
      case "drawdown":
        return (
          <div className='text-[#393939] font-semibold'>
            {cellValue.toFixed(4)}
          </div>
        );
      case "avg_cost_basis":
        return (
          <div className='text-[#393939]'>
            ${cellValue}
          </div>
        )
      case "realized_pnl":
        return (
          <div className='text-[#393939]'>
            ${cellValue}
          </div>
        )
      case "cumulative_slippage_trade_fees":
        return (
          <div className='text-[#393939]'>
            ${cellValue}
          </div>
        )
      case "slippage_trade_fees":
        return (
          <div className='text-[#393939]'>
            ${cellValue}
          </div>
        )
      case "unrealized_pnl":
        return (
          <div className='text-[#393939]'>
            ${cellValue}
          </div>
        )
      case "realized_pnl_btc":
        return (
          <div className='text-[#393939]'>
            ${cellValue}
          </div>
        )
      case "order_size_usd":
        return (
          <div className='text-[#393939]'>
            ${Number(cellValue).toFixed(2)}
          </div>
        )
      case "order_size_asset":
        return (
          <div className='text-[#393939]'>
            {truncateToFourDecimalPlaces(Number(cellValue))}
          </div>
        )

        
      default:
        return cellValue;
    }
  }, []);

  return (
    <>
     {users.length < 1 ? <SkeletonTable /> :
       <Table
         selectionMode={selectionMode}
         className="table w-full rounded-xl"
         aria-label="Table Component"
         bottomContent={
           <div className="flex w-full justify-center">
             <Pagination
               isCompact
               showControls
               classNames={{
                 wrapper: "h-4 rounded",
                 item: "h-8 text-small rounded-none bg-transparent",
                 cursor: "bg-[#393939] text-white font-bold text-[#ffff]",
               }}
               page={page}
               total={pages}
               onChange={(page) => setPage(page)}
             />
           </div>
         }
       >
         <TableHeader className="!border-b" columns={columns}>
           {(column) => (
             <TableColumn
               className="!border-b text-center !rounded-[2px] !bg-[#F9FAFB] !text-[#101828] !px-2"
             >
               {column.label}
             </TableColumn>
           )}
         </TableHeader>
         <TableBody items={items}>
           {(item) => (
             <TableRow
               key={uuidv4()}
               className={`relative text-[#667085] text-[.8rem] cursor-pointer py-4 ${item.isGroupHeader ? 'bg-[#f7f6f3]' : ''}`}
               onClick={() => item.isGroupHeader && toggleExpand(item.position_id)}
             >
               {(columnKey) => (
                 <TableCell className="py-2 border-b text-[.8rem] text-center align-middle">
                   {getKeyValue(item, columnKey)}
                 </TableCell>
               )}
             </TableRow>
           )}
         </TableBody>
       </Table>
     }
    </>
  );
}
