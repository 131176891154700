import React from 'react'
import SkeletonElement from './SkeletonBase'
import Shimmer from '../minerPerformance/Shimmer'

function SkeletonBox() {
  return (
    <div className='relative flex justify-center items-center flex-wrap w-full gap-2 overflow-x-hidden'>
    {[1,2,3,4,5,6].map((n)=>(
         <div className='bg-[#ddd] h-[100px] w-[28%] rounded-lg m-4'>
      
         </div>
    ))}
    <Shimmer/>
    </div>
   
  )
}

export default SkeletonBox